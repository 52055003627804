import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PartnerVehicle } from '@app-graphql';
import { GeolocationService, ListingService } from '@app-services';

type MarkerWithPartnerObjectKey = (google.maps.Marker & { id: string; });
type MarkerOptionsWithPartnerObjectKey = (google.maps.MarkerOptions & { id: string; });

@Component({
    selector: 'app-car-map',
    templateUrl: './car-map.component.html',
    styleUrls: ['./car-map.component.scss'],
})
export class CarMapComponent implements OnInit, OnDestroy {

    public map: google.maps.Map;
    public options: google.maps.MapOptions;
    public markers: google.maps.Marker[] = [];
    public infoWindow: google.maps.InfoWindow;

    public currentCoords = this.geolocationService.getCurrentCoords();
    public partnerVehicles: BehaviorSubject<PartnerVehicle[]> = this.listingService.state.partnerVehicles;
    public partnerObject: object = {};
    public showSpinner = false;
    public subscription: Subscription;
    public markerDataItems: (Location & PartnerVehicle)[] = [];

    constructor(
        private translate: TranslateService,
        private listingService: ListingService,
        private geolocationService: GeolocationService,
    ) {
    }

    public ngOnInit(): void {
        this.initCoordListener();
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    public initCoordListener(): void {
        this.currentCoords.subscribe((res) => {
            this.initMap();
            if (this.map) {
                this.map.setCenter({
                    lat: Number(res.latitude),
                    lng: Number(res.longitude),
                });
            }
        });
    }

    public initMap(): void {
        const currentCoords = this.geolocationService.getCurrentCoords().getValue();

        // Default to Netherlands
        let coords = {
            lat: 52.2715632482484,
            lng: 4.631475476257601,
        };

        if (currentCoords.longitude) {
            coords = { lat: Number(currentCoords.latitude), lng: Number(currentCoords.longitude) };
        }

        this.subscription = this.partnerVehicles.subscribe((data) => {
            this.initMarkerData(data);
        });
        this.listingService.state.partnerVehiclesLoading.subscribe((val) => {
            this.showSpinner = val;
        });

        this.options = {
            center: coords,
            zoom: 12,
            mapTypeId: 'roadmap',
            panControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP,
            },
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
        };

        this.infoWindow = new google.maps.InfoWindow();
    }

    public mapReady(map: google.maps.Map): void {
        this.map = map;
        this.markers?.forEach((marker) => {
            marker.setMap(this.map);
        });
    }

    public initMarkerData(data: PartnerVehicle[]): void {
        this.partnerObject = {};
        this.markerDataItems = [];

        if (! this.markerDataItems || ! this.markerDataItems.length) {
            data.forEach((car) => {
                const { partnerLocation } = car;
                if (partnerLocation) {
                    this.partnerObject[partnerLocation.id] = this.partnerObject[partnerLocation.id] || [];
                    this.partnerObject[partnerLocation.id].push({
                        ...partnerLocation!.location,
                        vehicle: car,
                    });
                }
            });

            for (const [key, value] of Object.entries(this.partnerObject)) {
                const marker = new google.maps.Marker({
                    map: this.map,
                    position: { lat: value[0].lat, lng: value[0].lng },
                    title: value[0].vehicle.partnerLocation.rentalPartner.name,
                    icon: '/assets/img/svg/car-location.svg',
                    animation: google.maps.Animation.DROP,
                    id: key,
                } as MarkerOptionsWithPartnerObjectKey);

                marker.addListener('click', () => this.handleMarkerClick(marker as MarkerWithPartnerObjectKey));

                this.markers.push(marker);
            }

            const currentCoords = this.geolocationService.getCurrentCoords().getValue();
            if (currentCoords) {
                // Also add a marker for your current location
                this.markers.push(new google.maps.Marker({
                    map: this.map,
                    position: { lat: Number(currentCoords.latitude), lng: Number(currentCoords.longitude) },
                    title: 'Uw locatie',
                }));
            }
        }
    }

    public handleMarkerClick(marker: MarkerWithPartnerObjectKey): void {
        const carTypeArray = this.partnerObject[marker.id];
        if (carTypeArray) {
            let contentString = '';
            const moreInfo = this.translate.instant('general.moreInfo');
            carTypeArray.forEach((car: any) => {
                contentString += `
                <div class="info-window-car">
                    <p class="info-window-car__title">
                        ${car.vehicle.vehicleMake.name} ${car.vehicle.vehicleModel.name}
                        <div class="small">${car.vehicle.vehicleType.name}</div>
                    </p>
                    <a class="info-window-car__link"
                        href="/detail/${car.vehicle.id}/${car.vehicle.partnerLocation.id}">
                        ${moreInfo}
                    </a>
                </div>
            `;
            });

            this.infoWindow.setContent(`
                <div class="info-window">
                    <h4>${carTypeArray[0].vehicle.partnerLocation.rentalPartner.name}</h4>
                    ${contentString}
                </div>
            `);

            this.infoWindow.open(this.map, marker);
        }
    }

}
