import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs'
import { FetchResult } from '@apollo/client/core'
import moment from 'moment/moment'
import { equals, reject } from 'ramda'
import { catchError } from 'rxjs/operators'

import {
    CreateCustomerMutation,
    CreateCustomerMutationService,
    CreateCustomerMutationVariables,
    CreateRentalMutation,
    CreateRentalMutationService,
    CreateRentalMutationVariables,
    CustomersQueryService,
    Driver,
    Maybe,
    ReisbalansCustomer,
    ReisbalansCustomerMutationService,
    Scalars,
    VehicleExtra,
} from '@app-graphql'
import { FilterService } from '@app-services/filter/filter.service'
import { LocalStorageService } from '@app-services/localstorage/localstorage.service'
import { WindowService } from '@app-services/window/window.service'

const emptyRentalState = {
    customerId: '' as Scalars['ID']['output'],
    partnerVehicleId: '' as Scalars['ID']['output'],
    partnerLocationId: '' as Scalars['ID']['output'],
    dateTimeFrom: '' as Maybe<string>,
    dateTimeTo: '' as Maybe<string>,
    deliveryType: 'deliver',
    deliveryTime: '' as Maybe<string>,
    driver: {
        firstName: '' as string,
        lastName: '' as string,
        email: '' as string,
        phone: '' as string,
        invoiceReference: '' as Maybe<string>,
    } as Partial<Driver>,
    totalKms: null as Maybe<number>,
    extras: [] as Maybe<[]>,
    streetName: '' as string,
    streetNumber: 0 as number,
    streetNumberSuffix: '' as string,
    postalCode: '' as Maybe<string>,
    city: '' as Maybe<string>,
    country: '' as Maybe<string>,
    remarks: '' as Maybe<string>,
    referralCode: '' as Maybe<string>,
    companyName: '' as Maybe<string>,
    destinationCountry: '' as Maybe<string>,
    contactPerson: {
        function: '' as Maybe<string>,
        firstName: '' as Maybe<string>,
        surName: '' as Maybe<string>,
        email: '' as Maybe<string>,
        phone: '' as Maybe<string>,
    },
}

const emptyCustomer = {
    city: '',
    country: '',
    email: '',
    firstName: '',
    id: '',
    licenseNumber: '',
    phone: '',
    postalCode: '',
    streetName: '',
    streetNumber: 0,
    streetNumberSuffix: '',
    surName: '',
}

// TODO: Since the Graphql schema changed a few times during development not all property names correspondent tot the
//  graphql schema equivalent, we probably want to change those when we got any time left.
@Injectable({
    providedIn: 'root',
})
export class CheckoutService {

    public firstTimeRunningServiceForCustomer = true
    public firstTimeRunningServiceForRental = true

    public state = {
        stepIsAccessible: {
            extras: new BehaviorSubject(false),
            delivery: new BehaviorSubject(false),
            personalInformation: new BehaviorSubject(true),
            finalize: new BehaviorSubject(true),
        },
        createCustomerFromApiResponse: new Subject() as Subject<FetchResult<CreateCustomerMutation>>,
        createRentalFromApiResponse: new Subject() as Subject<FetchResult<CreateRentalMutation>>,
        customer: new BehaviorSubject(emptyCustomer as CreateCustomerMutationVariables),
        rental: new BehaviorSubject(emptyRentalState),
        cartClearedState: new Subject<boolean>(),
        reisBalansCustomer: new BehaviorSubject<ReisbalansCustomer>({
            email: '' as Maybe<string>,
            firstName: '' as Maybe<string>,
            infix: '' as Maybe<string>,
            lastName: '' as Maybe<string>,
            leaseBicycleCategory: '' as Maybe<string>,
            phoneNumber: '' as Maybe<string>,
            employeeUuid: '' as Maybe<string>,
            organizationUuid: '' as Maybe<string>,
        }),
        extrasResolvedByName: new BehaviorSubject([]) as unknown as BehaviorSubject<VehicleExtra[]>,
    }

    constructor(
        private localStorageService: LocalStorageService,
        private windowService: WindowService,
        private filterService: FilterService,
        private customersQueryService: CustomersQueryService,
        private createCustomerMutationService: CreateCustomerMutationService,
        private createRentalMutationService: CreateRentalMutationService,
        private reisBalansCustomerMutationService: ReisbalansCustomerMutationService,
    ) {
    }

    public getStateCustomer() {
        const reisBalansUserKey = this.localStorageService.getOnLocalStorage('reisbalansUserKey')
        if (reisBalansUserKey) {
            this.reisBalansCustomerMutationService.mutate({ id: reisBalansUserKey }).subscribe((res) => {
                const customer = res.data?.reisbalansCustomer as ReisbalansCustomer
                this.state.reisBalansCustomer.next(customer)
                this.state.customer.next({
                    city: '',
                    country: 'Nederland',
                    email: customer.email ?? '',
                    firstName: customer.firstName ?? '',
                    licenseNumber: '',
                    phone: customer.phoneNumber ?? '',
                    postalCode: '',
                    streetName: '',
                    streetNumber: 0,
                    streetNumberSuffix: '',
                    surName: customer.lastName
                        ? customer.lastName + (customer.infix ? ', '.concat(customer.infix) : '')
                        : '',

                })
            })
        } else if (this.firstTimeRunningServiceForCustomer) {
            this.firstTimeRunningServiceForCustomer = false
            const localStorageData = this.localStorageService.getOnLocalStorage('local_CheckoutService_customer')
            if (localStorageData) {
                this.state.customer.next(localStorageData)
            }
        }
        return this.state.customer
    }

    public getStateRental() {
        if (this.firstTimeRunningServiceForRental) {
            this.firstTimeRunningServiceForRental = false
            const localStorageData = this.localStorageService.getOnLocalStorage('local_CheckoutService_rental')
            if (localStorageData) {
                this.state.rental.next(localStorageData)
            }
        }

        return this.state.rental
    }

    public async runCheckoutProcess() {
        this.createCustomer()

        await firstValueFrom(this.state.createCustomerFromApiResponse)
            .then((customer) => {
                const customerId = customer.data?.createCustomer.id
                this.setRentalValue({ name: 'customerId', value: customerId })
                this.createRental()
            })

        return this.state.createRentalFromApiResponse
    }

    public async createRental(): Promise<void> {
        const rentalMutationVariables = await this.buildRentalMutationVariables()

        await firstValueFrom(this.createRentalMutationService.mutate(rentalMutationVariables, {}).pipe(
            catchError((error) => {
                console.warn(error)
                return new BehaviorSubject({
                    data: {
                        createRental: {
                            id: 'error',
                        },
                    },
                })
            }),
        )).then((res) => {
            this.state.createRentalFromApiResponse.next(res)
        })
    }

    public async buildRentalMutationVariables(): Promise<CreateRentalMutationVariables> {
        const rentalState = this.state.rental.getValue()
        const filterState = this.filterService.getFilter().getValue()
        const variables = {
            onContext: this.windowService.getHostname(),
            partnerLocationId: rentalState.partnerLocationId,
            customerId: rentalState.customerId,
            partnerVehicleId: rentalState.partnerVehicleId,
            dateTimeFrom: moment(filterState.dateTimeFrom).format('YYYY-MM-DD HH:mm:ss'),
            dateTimeTo: moment(filterState.dateTimeTo).format('YYYY-MM-DD HH:mm:ss'),
            deliveryType: filterState.deliveryMethod || 'deliver',
            deliveryTime: rentalState.deliveryTime,
            driver: rentalState.driver,
            extraKms: rentalState.totalKms,
            extras: rentalState.extras,
            streetName: rentalState.streetName,
            streetNumber: Number(rentalState.streetNumber),
            streetNumberSuffix: rentalState.streetNumberSuffix,
            postalCode: rentalState.postalCode,
            city: rentalState.city,
            country: rentalState.country,
            referralCode: rentalState.referralCode,
            destinationCountry: rentalState.destinationCountry,
            remarks: rentalState.remarks,
            companyName: rentalState.companyName,
        } as CreateRentalMutationVariables

        if (rentalState.contactPerson?.firstName) {
            variables.contactPerson = reject(equals(''))({
                function: rentalState.contactPerson.function,
                firstName: rentalState.contactPerson.firstName,
                surName: rentalState.contactPerson.surName,
                email: rentalState.contactPerson.email,
                phone: rentalState.contactPerson.phone,
            })
        }

        // console.log('buildRentalMutationVariables', variables);
        return reject(equals(''))(variables as any) as unknown as CreateRentalMutationVariables
    }

    public createCustomer(): void {
        const input = reject(equals(''))(this.state.customer.getValue() as any) as CreateCustomerMutationVariables
        firstValueFrom(this.createCustomerMutationService.mutate(input)).then((res) => {
            this.state.createCustomerFromApiResponse.next(res)
        })
    }

    public getCustomers(): void {
        // TODO: Check if we need this call, we probably don't
        this.customersQueryService.fetch().subscribe((res) => {
            console.log(res)
        })
    }

    public setRentalValue(input: { name: string, value: any }): void {
        const currentRentalData = this.state.rental.getValue()
        currentRentalData[input.name] = input.value
        this.state.rental.next(currentRentalData)
        this.localStorageService.storeOnLocalStorage(
            { name: 'local_CheckoutService_rental', value: currentRentalData },
        )
    }

    public setDriver(driver: Partial<Driver> | null): void {
        const currentRentalData = this.state.rental.getValue()
        currentRentalData.driver = driver
        this.state.rental.next(currentRentalData)
        this.localStorageService.storeOnLocalStorage(
            { name: 'local_CheckoutService_rental', value: currentRentalData },
        )
    }

    public clearRentalValues(): void {
        this.state.customer.next(emptyCustomer)
        this.localStorageService.storeOnLocalStorage(
            { name: 'local_CheckoutService_customer', value: emptyCustomer },
        )
    }

    public setCustomerValue(input: { name: string, value: any }): void {
        const currentCustomerData = this.state.customer.getValue()
        currentCustomerData[input.name] = input.value

        this.state.customer.next(currentCustomerData)
        this.localStorageService.storeOnLocalStorage({
            name: 'local_CheckoutService_customer',
            value: currentCustomerData,
        })
    }

}
