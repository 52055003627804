import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { combineLatest, Subscription } from 'rxjs'

import { Customer, CustomerTypeEnum, CustomerTypeWithBothEnum, PartnerLocation, PartnerVehicle } from '@app-graphql'
import { CheckoutService, DomainService, FilterService, ListingService } from '@app-services'
import { CalculateDurationDaysPipe } from '@app-pipes'

@Component({
    selector: 'app-order-info-card',
    templateUrl: './order-info-card.component.html',
    styleUrls: ['./order-info-card.component.scss'],
})
export class OrderInfoCardComponent implements OnInit, OnDestroy {

    @Input()
    public title: string = 'orderDelivery.yourOrder'

    public isLoading = true
    public hasData = false

    public rentalItem: any
    public customerItem: Partial<Customer>

    public car: Partial<PartnerVehicle>
    public partnerLocation: PartnerLocation

    public extrasByName = this.checkoutService.state.extrasResolvedByName
    public filterGroups = this.filterService.state.filterGroups
    public vehicleId: string
    public locationId: string

    public price: number
    public extrasOneTimeTotalPrice: number
    public extrasPerDayTotalPrice: number
    public priceKm: number
    public showIncVat = false

    public subscription: Subscription

    constructor(
        private calculateDurationDaysPipe: CalculateDurationDaysPipe,
        private checkoutService: CheckoutService,
        private readonly domainService: DomainService,
        private listingService: ListingService,
        private filterService: FilterService,
        private router: Router,
    ) {
        this.listingService.state.showIncVat.subscribe((showIncVat) => {
            this.showIncVat = showIncVat
        })

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(async () => {
                await this.initialize()
            })

    }

    public async ngOnInit(): Promise<void> {
        await this.initialize()
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe()
    }

    public async initialize(): Promise<void> {
        const serviceProvider = await this.domainService.getServiceProvider()
        this.listingService.customerType = serviceProvider.customerType === CustomerTypeWithBothEnum.B2B
            ? CustomerTypeEnum.B2B
            : CustomerTypeEnum.B2C

        const carRental = this.checkoutService.state.rental.getValue()
        this.vehicleId = carRental?.partnerVehicleId
        this.locationId = carRental?.partnerLocationId

        if (carRental && this.vehicleId && this.locationId) {
            this.getData()
        } else {
            this.isLoading = false
            this.hasData = false
        }
    }

    public getData(): void {
        this.checkoutService.getStateRental().subscribe((rental) => {
            this.rentalItem = rental
        })

        this.checkoutService.getStateCustomer().subscribe((customer) => {
            this.customerItem = customer
        })

        this.subscription = this.listingService.getDetail(this.vehicleId, this.locationId).subscribe((res) => {
            this.showIncVat = this.listingService.state.showIncVat.getValue()
            this.car = res.data.partnerVehicleById as unknown as Partial<PartnerVehicle>
            this.price = this.car?.priceOnInterval ?? 0
            this.priceKm = this.car?.priceExtraKmPerDay ?? 0

            this.partnerLocation = this.car?.partnerLocation;
            this.hasData = true
            this.isLoading = false
        })
        combineLatest([this.checkoutService.getStateRental(), this.listingService.state.selectedVehicle])
            .pipe(
                map(((res) => ({
                    selectedExtras: res[0].extras,
                    vehicleExtras: res[1]?.data?.partnerVehicleById?.vehicleExtras,
                }))),
            )
            .subscribe((res) => {
                const found: any[] = []
                res.selectedExtras?.forEach((selectedExtra) => {
                    const object = res.vehicleExtras?.find((extraObject) => extraObject?.id === selectedExtra)
                    found.push(object)
                })
                this.checkoutService.state.extrasResolvedByName.next(found)
            })

        this.extrasByName.subscribe((extras) => {
            const numDays = this.calculateDurationDaysPipe.transform(
                this.rentalItem?.dateTimeFrom,
                this.rentalItem?.dateTimeTo,
            ) ?? 1

            const extrasPricePerDay = extras.reduce((acc, extra) => acc + (extra?.rates[0].pricePerDay ?? 0), 0)

            this.extrasPerDayTotalPrice = extrasPricePerDay * numDays
            this.extrasOneTimeTotalPrice = extras.reduce((acc, extra) => acc + (extra?.rates[0].oneTimeFee ?? 0), 0)
        })
    }

    public setVatIncluded(showIncVat: boolean): void {
        this.listingService.state.showIncVat.next(showIncVat)
    }

}
