<div class="car-info-card">
    <article class="grid">

        <div class="car-info-card__image__container col-12 sm:col-6">
            <a [routerLink]="'/detail/' + car.id + '/' + car.partnerLocation!.id">
                <img class="car-info-card__image"
                     [src]="car.vehicleAttribute?.image"
                     [alt]="car.vehicleMake.name + ' ' + car.vehicleModel.name"
                />
            </a>
        </div>

        <div class="col-12 sm:col-6">
            <h1 class="car-info-card__title">
                <a [routerLink]="'/detail/' + car.id + '/' + car.partnerLocation!.id" class="link-unstyled">
                    {{ car.vehicleMake.name }} {{ car.vehicleModel.name }}
                </a>
                <br>
                <span class="car-info-card__attribute__note">
                    {{ 'carInfo.orSimilar' | translate }}
                </span>
            </h1>

            <div>

                <div class="car-info-card__attribute">
                    <span class="car-info-card__attribute__icon">
                      <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path
                              d="M0 0h32v32H0z" /><path
                              d="M19.04 9a2 2 0 0 1 1.746 1.027L23 14h2v1.5h-1.026a2 2 0 0 1 .026.323V20a1 1 0 0 1-1 1v.5a1.5 1.5 0 0 1-3 0V21h-7v.5a1.5 1.5 0 0 1-3 0V21a1 1 0 0 1-1-1v-4.187a2 2 0 0 1 .025-.313H8V14h2l2.214-3.973A2 2 0 0 1 13.96 9h5.078ZM21 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm6.685-6h-4.37a1.76 1.76 0 0 0-1.426.73l-.085.128L10.93 14H22.07l-1.875-3.142a1.76 1.76 0 0 0-1.358-.851L18.685 10Z"
                              fill="#d8d8d8" /></g></svg>
                    </span>
                    <b class="car-rental-partner">
                        {{ car.partnerLocation.rentalPartner.name }}
                    </b>
                </div>

                <p class="car-info-card__attribute">
                    <span class="car-info-card__attribute__icon">
                      <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path
                              d="M0 0h32v32H0z" /><path
                              d="M16 7c3.314 0 6 2.75 6 6.144a6.25 6.25 0 0 1-.5 2.462h.003l-.046.096c-.05.113-.105.225-.162.334l-4.02 8.273a1.2 1.2 0 0 1-2.071.152l-.068-.112-4.587-8.604h.014a6.244 6.244 0 0 1-.563-2.6C10 9.75 12.686 7 16 7Zm0 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                              fill="#d8d8d8" /></g></svg>
                    </span>
                    <span>
                        <span>
                            {{ 'carInfo.pickUpAt' | translate }}
                        </span>
                        <b>
                            {{ car.partnerLocation?.location?.name }}
                        </b>
                    </span>
                </p>

                <p class="car-info-card__attribute">
                    <span class="car-info-card__attribute__icon">
                          <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><g fill="none"
                                                                                            fill-rule="evenodd"><path
                                  d="M0 0h32v32H0z" /><path
                                  d="M19.674 11.147 9.205 15.003a1.2 1.2 0 0 0 .126 2.291l4.005.994a1.2 1.2 0 0 1 .876.876l.974 3.925a1.2 1.2 0 0 0 2.295.112l3.738-10.526a1.2 1.2 0 0 0-1.545-1.528Z"
                                  fill="#d8d8d8" /></g></svg>
                    </span>
                    <span>
                        <b>
                            {{ car.partnerLocation?.distance | number:'1.2-2' }} km
                        </b> {{ 'carInfo.distanceToLocation' | translate }}
                    </span>
                </p>

                <div class="car-info-card__attribute">
                     <span class="car-info-card__attribute__icon ml-1">
                         <i class="pi pi-check"></i>
                    </span>
                    <span>
                        <b>
                            <ng-container *ngIf="! car.discountPercentage; else discountedPrice">
                                {{ car.priceOnInterval | vat | currency : 'EUR' }} +&nbsp;
                            </ng-container>
                            <ng-template #discountedPrice>
                                <span class="price-original">{{ car.originalPriceOnInterval | vat | currency : 'EUR' }}</span>
                                <div class="price-discount-badge">-{{ car.discountPercentage }}%</div>
                                <br>
                                <span class="price-discounted">{{ car.priceOnInterval | vat | currency : 'EUR' }}</span>
                                +
                            </ng-template>
                            {{ car.priceExtraKmPerDay | vat | currency : 'EUR' }}/km
                        </b>
                        <span class="car-info-card__attribute__note">
                            incl. {{ car.freeKmsPerDay }} km
                            {{ 'general.perDay' | translate }}
                        </span>
                    </span>
                </div>

                <ng-container *ngIf="car.partnerLocation.paymentMethods.length === 1">
                    <div class="car-info-card__attribute car-info-card__attribute__paymentmethod"
                         [ngStyle]="{color: 'var(--color-warning)'}">
                         <span class="car-info-card__attribute__icon ml-1">
                             <i class="pi pi-euro"></i>
                        </span>
                        <span>Let op: Deze locatie accepteert alleen
                            <ng-container *ngFor="let paymentMethod of car.partnerLocation.paymentMethods">
                                {{ paymentMethod.name }}
                            </ng-container></span>
                    </div>
                </ng-container>
            </div>

            <div class="grid pt-3 pl-2 pr-2">
                <button (click)="goToCheckout(car.id, car.partnerLocation!.id)"
                        pButton
                        pRipple
                        type="button"
                        [label]="'general.orderNow' | translate"
                        class="car-info-card__cta p-button-rounded col-12 md:col-5">
                </button>
                <button [routerLink]="'/detail/' + car.id + '/' + car.partnerLocation!.id"
                        pButton
                        pRipple
                        type="button"
                        [label]="'general.moreInfo' | translate"
                        class="car-info-card__cta p-button-secondary p-button-outlined p-button-rounded col-12 md:col-offset-1 md:col-5">
                </button>

            </div>
        </div>

    </article>

</div>
